<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12" md="4">
                    <ValidationProvider name="type" rules="required" v-slot="{valid, errors}">
                        <b-form-group>
                            <label>{{ $t('education_status') }}</label>
                            <parameter-selectbox v-model="formData.type"
                                                 code="education_statuses"
                                                 sort="id"
                                                 :validate-error="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="8">
                    <label>{{ $t('education_information') }}</label>
                    <div class="border rounded p-4">
                        <div v-if="formData.type==null">
                            <b-form-group :title="$t('school')+' / '+$t('university')">
                                <div class="label-as-input">-</div>
                            </b-form-group>
                        </div>
                        <div v-else>
                            <div v-if="formData.type && formData.type=='high_school'">
                                <ValidationProvider name="school_id" rules="required" v-slot="{valid, errors}">
                                    <b-form-group>
                                        <label>{{ $t('school') }}</label>
                                        <school-selectbox-auto-complate v-model="formData.school_id"
                                                                        :validate-error="errors[0]"
                                        />
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div v-else>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <ValidationProvider name="university_type" rules="required" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('university_type')">
                                                <parameter-selectbox v-model="university_type"
                                                                     :validateError="errors[0]"
                                                                     code="university_types"
                                                />
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col cols="12" md="6">
                                        <ValidationProvider name="university_country_id" rules="required" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('country')">
                                                <country-selectbox v-model="university_country_id"
                                                                   :validateError="errors[0]"
                                                />
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col cols="12">
                                        <ValidationProvider name="school_id" rules="required" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('university')">
                                                <university-selectbox v-model="formData.school_id"
                                                                      :type="university_type"
                                                                      :is-type-required="true"
                                                                      :countryId="university_country_id"
                                                                      :is-country-required="true"
                                                                      :validate-error="errors[0]"
                                                />
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col cols="12">
                                        <ValidationProvider name="program" rules="required" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('program')">
                                                <b-form-input v-model="formData.program"
                                                              :state="errors[0] ? false : null"
                                                />
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                        <b-row>
                            <b-col cols="12" md="4">
                                <ValidationProvider name="graduate_date" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('graduate_date')">
                                        <select-date v-model="formData.graduate_date"
                                                     :validationError="errors[0]"
                                        />
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="12" md="4">
                                <ValidationProvider name="diploma_number" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('diploma_number')">
                                        <b-form-input v-model="formData.diploma_number"
                                                      :state="errors[0] ? false : null"
                                                      type="number"
                                        />
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="12" md="4">
                                <ValidationProvider name="diploma_point" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('diploma_point')">
                                        <b-form-input v-model="formData.diploma_point"
                                                      :state="errors[0] ? false : null"
                                                      type="number"
                                                      step="0.01"
                                        />
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </div>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary"
                          @click="updateForm"
                          :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    // Components
    import CountrySelectbox from '@/components/interactive-fields/CountrySelectbox';
    import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';
    import SchoolSelectboxAutoComplate from '@/components/interactive-fields/SchoolSelectboxAutoComplate';
    import SelectDate from '@/components/interactive-fields/SelectDate';
    import SemestersSelectbox from '@/components/interactive-fields/SemestersSelectbox';
    import UniversitySelectbox from '@/components/interactive-fields/UniversitySelectbox';

    // Services
    import StudentEducationService from '@/services/StudentEducationService';

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'

    export default {
        components: {
            CountrySelectbox,
            ParameterSelectbox,
            SchoolSelectboxAutoComplate,
            SelectDate,
            SemestersSelectbox,
            UniversitySelectbox,

            ValidationProvider,
            ValidationObserver
        },
        props: {
            studentProgram: {
                type: Object,
                default: null
            },
            formId: {
                type: Number
            }
        },
        data() {
            return {
                formLoading: false,
                formData: {
                    type: null,
                    school_id: null,
                    education_id: null,
                    program: null,
                    graduate_date: null,
                    diploma_number: null,
                    diploma_point: null
                },
                university_type: null,
                university_country_id: null,

                schoolObject: {
                    value: null,
                    text: null
                }
            }
        },
        created() {
            this.get(this.formId)
        },
        methods: {
            async get(id) {
                StudentEducationService.get(id)
                                       .then(response => {
                                           let data = response.data.data;
                                           this.formData = {
                                               type: data.type,
                                               school_id: data.school_id,
                                               education_id: data.education_id,
                                               program: data.program,
                                               graduate_date: data.graduate_date,
                                               diploma_number: data.diploma_number,
                                               diploma_point: data.diploma_point
                                           }
                                           this.university_type = data.school.type
                                           this.university_country_id = data.school.country_id

                                           this.schoolObject = {
                                               value: data.school_id,
                                               text: data.school ? data.school.name : '-'
                                           }
                                       })
                                       .catch((e) => {
                                           this.showErrors(e)
                                       })
            },
            async updateForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid && this.studentProgram.id) {
                    let formData = {
                        student_number: this.studentProgram.student_number,
                        ...this.formData
                    }
                    this.formLoading = true;
                    StudentEducationService.update(this.formId, formData)
                                           .then(response => {
                                               this.$toast.success(this.$t('api.' + response.data.message));
                                               this.$emit('updateFormSuccess', true);
                                           })
                                           .catch(e => {
                                               this.showErrors(e, this.$refs.formModalValidate)
                                           })
                                           .finally(() => {
                                               this.formLoading = false
                                           })
                }
            }
        }
    }
</script>

