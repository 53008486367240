<template>
	<div>
		<multiselect
			:placeholder="$t('select')"
			v-model="selected"
			:options="options"
			:class="validateError != '' ? 'box-border-color':''"
			@input="handleInput"
			label="text"
			track-by="value"
			:multiple="false"
			:select-label="''"
			:selected-label="''"
			:deselect-label="''"
			:searchable="true">
            <span slot="noOptions">
                {{ $t('no_options') }}
            </span>
			<span slot="noResult">
                {{ $t('no_result') }}
            </span>
		</multiselect>
		<span class="invalid-feedback-custom"
		      v-show="validateError"
		      v-html="validateError">
        </span>
	</div>
</template>

<script>
	// Helpers
	import handleInput from "@/helpers/handleInput";
	import setSelected from "@/helpers/setSelected";
	
	// Services
	import CurriculumService from "@/services/CurriculumService";
	
	// Other
	import qs from "qs";
	
	export default {
		props: {
			value: {
				default: null
			},
			validateError: {
				type: String,
				default: ''
			},
			program_code: {
				type: String,
			},
		},
		data() {
			return {
				selected: this.value,
				options: []
			}
		},
		watch: {
			value: function (newValue) {
				this.selected = this.setSelected(newValue, this.options)
			},
			program_code: {
				handler: function (newValue, oldValue) {
					if (newValue != oldValue) {
						this.getOptions()
					}
				}
			}
		},
		created() {
			this.getOptions();
		},
		methods: {
			handleInput: handleInput,
			setSelected: setSelected,
			getOptions() {
				this.clearOptions()
				if (this.program_code == null) {
					return this.$emit("input", null)
					return;
				}
				const config = {
					params: {
						filter: {
							program_code: this.program_code,
						},
						limit: -1
					},
					paramsSerializer: (params) => qs.stringify(params, {encode: false})
				}
				CurriculumService.getAll(config)
				                 .then(response => {
					                 const data = response.data.data;
					                 data.map(item => {
						                 this.options.push({
							                 value: item.id,
							                 text: item.academic_year + ' - ' + item.program_name
						                 });
					                 });
				                 })
				                 .then(() => {
					                 this.selected = this.setSelected(this.value, this.options)
				                 })
			},
			clearOptions() {
				this.selected = null;
				this.options = [];
			}
		}
	}
</script>
