<template>
    <div class="overflow-y-scroll overflow-x-hidden w-100 d-none d-lg-block position-sticky profile-menu">
        <div v-for="(menu,menuKey) in menus"
             :key="menuKey"
             v-if="(forStudent==true && menu.showStudent) || (forStudent==false && checkPermission(menu.permission))"
        >
            <div v-if="menu.slug=='foreign_student_credentials'">
                <b-button variant="block justify-content-start h-auto font-weight-medium line-height-normal py-2 text-left mt-0 mb-1"
                          :class="[selectedMenu === menu.slug ? 'btn-primary' : 'secondary-border-lighter btn-outline-secondary']"
                          @click="changeMenu(menu.slug)"
                          v-if="studentProgram.nationality_code!='TR'"
                >
                    {{ menu.title }}
                </b-button>
            </div>
            <div v-else-if="menu.slug=='military_information'">
                <b-button variant="block justify-content-start h-auto font-weight-medium line-height-normal py-2 text-left mt-0 mb-1"
                          :class="[selectedMenu === menu.slug ? 'btn-primary' : 'secondary-border-lighter btn-outline-secondary']"
                          @click="changeMenu(menu.slug)"
                          v-if="studentProgram.gender=='Erkek'"
                >
                    {{ menu.title }}
                </b-button>
            </div>
            <div v-else>
                <b-button variant="block justify-content-start h-auto font-weight-medium line-height-normal py-2 text-left mt-0 mb-1"
                          :class="[selectedMenu === menu.slug ? 'btn-primary' : 'secondary-border-lighter btn-outline-secondary']"
                          @click="changeMenu(menu.slug)"
                >
                    {{ menu.title }}
                </b-button>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            value: {
                type: String,
                default: null
            },
            studentProgram: {
                type: Object,
                default: null
            },
            menuData: {
                type: Array,
                default: null
            },
            forStudent: {
                type: Boolean,
                default: false
            }
        },
        created() {
            this.menus = this.menuData;
            this.selectedMenu = this.value
        },
        watch: {
            value: function(newValue) {
                this.selectedMenu = newValue;
            },
            menuData: function(newValue) {
                this.menus = newValue;
            }
        },
        data() {
            return {
                menus: null,
                selectedMenu: null
            }
        },
        methods: {
            changeMenu(menu) {
                this.selectedMenu = menu
                this.$emit('input', menu)
            }
        }
    }
</script>
<style scoped>
    @media screen and (min-width: 992px) {
        .profile-menu {
            width: 150px !important;
        }
    }

    @media screen and (min-width: 1281px) {
        .profile-menu {
            width: 180px !important;
        }
    }

    @media screen and (min-width: 1400px) {
        .profile-menu {
            width: 200px !important;
        }
    }
</style>
