<template>
    <div>
        <page-header :title="$t('discipline_process')"
                     @filter-div-status="datatable.filterStatus=$event"
                     :isFilter="false"
                     :isColumns="true"
                     :isNewButton="checkPermission('discipline_store')"
                     @new-button-click="createFormShow">
            <template v-slot:columns>
                <div class="mb-1" v-for="(column,i) in datatable.columns" :key="i">
                    <b-form-checkbox
                        :id="'checkbox-'+i"
                        :name="'checkbox-'+i"
                        :value="false"
                        :unchecked-value="true"
                        v-model="column.hidden"
                        v-if="column.field!='buttons'"
                    >
                        {{ br2space(column.label) }}
                    </b-form-checkbox>
                </div>
            </template>
        </page-header>

        <datatable :rows="datatable.rows"
                   :columns="datatable.columns"
                   :query-params="datatable.queryParams"
                   v-show="datatable.showTable"
                   :is-loading="datatable.isLoading"
                   @on-page-change="onPageChange"
                   @on-per-page-change="onPerPageChange"
                   :title="''"
        />

        <CommonModal ref="formModal"
                     size="xl"
                     :onHideOnlyX="true"
                     @bHideModalHeaderClose="clearFormData()">
            <template v-slot:CommonModalTitle>
                <span v-if="formProcess=='create'">{{ $t('new') }}</span>
                <span v-if="formProcess=='update'">{{ $t('update') }}</span>
                <span v-if="formProcess=='upload_files'">{{ $t('uploaded_files') }}</span>
            </template>
            <template v-slot:CommonModalContent>
                <create-form :student-program-id="studentProgram.id"
                             @createFormSuccess="createFormSuccess"
                             v-if="formProcess=='create'"/>

                <update-form :form-id="formId"
                             @updateFormSuccess="updateFormSuccess"
                             v-if="formProcess=='update'"/>

                <upload-files :form-id="formId"
                              v-if="formProcess=='upload_files'"/>
            </template>
        </CommonModal>
    </div>
</template>
<script>
    // Template
    import PageHeader from '@/components/page/PageHeader';

    // Component
    import Datatable from '@/components/datatable/Datatable';
    import CommonModal from '@/components/elements/CommonModal';

    // Pages
    import CreateForm from '@/modules/students/pages/student/disciplineProcess/CreateForm';
    import UpdateForm from '@/modules/students/pages/student/disciplineProcess/UpdateForm';
    import UploadFiles from '@/modules/students/pages/student/disciplineProcess/UploadFiles';

    // Services
    import DisciplinesService from '@/services/DisciplinesService';

    // Other
    import moment from 'moment';

    export default {
        components: {
            PageHeader,

            Datatable,
            CommonModal,

            UpdateForm,
            CreateForm,
            UploadFiles
        },
        props: {
            studentProgram: {}
        },
        data() {
            return {
                formId: null,
                formProcess: null,

                datatable: {
                    rows: [],
                    showTable: true,
                    isLoading: false,
                    filterStatus: true,
                    total: 0,
                    queryParams: {
                        filter: {},
                        limit: 20,
                        page: 1
                    },
                    columns: [
                        {
                            label: '',
                            html: true,
                            field: 'buttons',
                            sortable: false,
                            tdClass: 'p-0 text-center w-40 align-middle',
                            buttons: [
                                {
                                    text: this.$t('edit'),
                                    class: 'ri-edit-box-line',
                                    permission: 'discipline_update',
                                    callback: (row) => {
                                        this.updateFormShow(row.id)
                                    }
                                },
                                {
                                    text: this.$t('uploaded_files'),
                                    class: 'ri-file-copy-2-line',
                                    permission: 'discipline_filelist',
                                    callback: (row) => {
                                        this.uploadFilesShow(row.id)
                                    }
                                },
                                {
                                    text: this.$t('delete'),
                                    class: 'ri-delete-bin-7-line',
                                    permission: 'discipline_delete',
                                    callback: (row) => {
                                        this.deleteForm(row.id)
                                    }
                                }
                            ]
                        },
                        {
                            field: 'student_number',
                            label: this.spaceToBR(this.toUpperCase('student_number')),
                            hidden: false,
                            sortable: false,
                            thClass: 'text-center',
                            tdClass: 'text-center'
                        },
                        {
                            field: 'name',
                            label: this.toUpperCase('name') + ' ' + this.toUpperCase('surname'),
                            hidden: false,
                            sortable: false,
                            formatFn: (value, row) => {
                                return value + ' ' + row.surname;
                            }
                        },
                        {
                            field: 'decision_number',
                            label: this.spaceToBR(this.changeText(this.$t('decision_number')).toUpper()),
                            hidden: false,
                            sortable: false,
                            thClass: 'text-center',
                            tdClass: 'text-center'
                        },
                        {
                            field: ('decision_text'),
                            label: this.changeText(this.$t('decision')).toUpper(),
                            hidden: false,
                            sortable: false,
                            thClass: 'text-center',
                            tdClass: 'text-center'
                        },
                        {
                            field: ('punishment_text'),
                            label: this.changeText(this.$t('punishment')).toUpper(),
                            hidden: false,
                            sortable: false
                        },
                        {
                            field: 'decision_date',
                            label: this.spaceToBR(this.changeText(this.$t('decision_date')).toUpper()),
                            hidden: false,
                            sortable: false,
                            thClass: 'text-center',
                            tdClass: 'text-center'
                        },
                        // {
                        //     field: 'stay_of_execution_decision',
                        //     label: this.spaceToBR(this.changeText(this.$t('status')).toUpper()),
                        //     hidden: false,
                        //     sortable: false,
                        //     thClass: 'text-center',
                        //     tdClass: 'text-center',
                        //     formatFn:(value, row)=>{
                        //         return value ==='yes' ? this.$t('continue') : this.$t('cancel')
                        //     }
                        // }
                    ]
                }
            }
        },
        mounted() {
            this.filterClear();
        },
        methods: {
            filter() {
                this.datatable.queryParams.page = 1;
                this.getRows()
            },
            filterClear() {
                this.filterSet();
                this.getRows()
            },
            filterSet() {
                var keys = Object.keys(this.datatable.queryParams.filter)
                for (var i = 0; i < keys.length; i++) {
                    let key = keys[i]
                    this.datatable.queryParams.filter[key] = null;
                }
            },
            onPageChange(params) {
                this.datatable.queryParams.page = params.currentPage
            },
            onPerPageChange(params) {
                this.datatable.queryParams.limit = params.currentPerPage;
            },
            getRows() {
                this.datatable.showTable = true;
                this.datatable.isLoading = true;

                DisciplinesService.getStudentAll(this.studentProgram.id)
                                  .then((res) => {
                                      let data = res.data.data;

                                      let newData = data.map(discipline => {
                                          return {
                                              ...discipline,
                                              stay_of_execution_decision: discipline.stay_of_execution_decision ? this.$t('yes') : this.$t('no'),
                                              decision_date: moment(discipline.decision_date).format('DD.MM.YYYY'),
                                              punishment_start_date: moment(data.punishment_start_date).format('DD.MM.YYYY'),
                                              punishment_end_date: moment(data.punishment_end_date).format('DD.MM.YYYY')
                                          }
                                      })
                                      this.datatable.rows = newData;
                                      this.datatable.total = res.data.pagination.total;
                                  })
                                  .catch((e) => {
                                      this.showErrors(e)
                                  })
                                  .finally(() => {
                                      this.datatable.isLoading = false
                                  })
            },

            // Clear
            clearFormData() {
                this.formId = null
                this.formProcess = null
            },

            // Create Form
            createFormShow() {
                this.formId = null;
                this.formProcess = 'create';
                this.$refs.formModal.$refs.commonModal.show();
            },
            createFormSuccess() {
                this.clearFormData();
                this.$refs.formModal.$refs.commonModal.hide()
                this.getRows();
            },

            // Update Form
            updateFormShow(id) {
                this.formId = id;
                this.formProcess = 'update'
                this.$refs.formModal.$refs.commonModal.show();
            },
            updateFormSuccess() {
                this.clearFormData();
                this.$refs.formModal.$refs.commonModal.hide()
                this.getRows();
            },

            // Upload Files Form
            uploadFilesShow(id) {
                this.formId = id;
                this.formProcess = 'upload_files'
                this.$refs.formModal.$refs.commonModal.show();
            },

            // Delete
            deleteForm(id) {
                this.$swal
                    .fire({
                        text: this.$t('are_you_sure_to_delete'),
                        showCancelButton: true,
                        cancelButtonText: this.$t('no'),
                        confirmButtonText: this.$t('yes')
                    })
                    .then(result => {
                        if (result.isConfirmed) {
                            DisciplinesService.del(id)
                                              .then(response => {
                                                  this.$toast.success(this.$t('api.' + response.data.message));
                                                  this.getRows();
                                              })
                                              .catch(e => {
                                                  this.showErrors(e)
                                              })
                        }
                    })
            }
        }
    }
</script>

