<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row class="mb-4">
                <b-col cols="12" md="6">
                    <b-card class="soft h-100">
                        <template #header>
                            <span v-html="$t('horizontal_or_vertical_coming_from_university_1')"></span>
                        </template>
                        <template class="pb-0">
                            <b-form-group :label="$t('university')">
                                <ValidationProvider name="from_university_id" v-slot="{valid, errors}">
                                    <university-selectbox v-model="formData.from_university_id"
                                                          :validate-error="errors[0]"
                                    />
                                </ValidationProvider>
                            </b-form-group>
                            <b-form-group :label="$t('faculty')">
                                <ValidationProvider name="from_faculty" v-slot="{valid, errors}">
                                    <b-form-input v-model="formData.from_faculty"
                                                  :class="errors[0] ? 'is-invalid':''"
                                    />
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                </ValidationProvider>
                            </b-form-group>
                            <b-form-group :label="$t('program')">
                                <ValidationProvider name="from_program" v-slot="{valid, errors}">
                                    <b-form-input v-model="formData.from_program"
                                                  :class="errors[0] ? 'is-invalid':''"
                                    />
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                </ValidationProvider>
                            </b-form-group>
                            <b-row>
                                <b-col cols="12" md="6">
                                    <b-form-group :label="$t('number_of_semesters')">
                                        <ValidationProvider name="from_number_of_semesters" v-slot="{valid, errors}">
                                            <period-selectbox v-model="formData.from_number_of_semesters"></period-selectbox>
                                        </ValidationProvider>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="6">
                                    <b-form-group :label="$t('gno')">
                                        <ValidationProvider name="cpga" v-slot="{valid, errors}">
                                            <b-form-input v-model="formData.from_cgpa"
                                                          :class="errors[0] ? 'is-invalid':''"
                                                          type="number"
                                                          step="0.01"
                                            />
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                        </ValidationProvider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </template>
                    </b-card>
                </b-col>
                <b-col cols="12" md="6">
                    <b-card class="soft h-100">
                        <template #header>
                            <span v-html="$t('horizontal_or_vertical_attended_university_1')"></span>
                        </template>
                        <b-form-group :label="$t('university')">
                            <ValidationProvider name="to_university_id" v-slot="{valid, errors}">
                                <university-selectbox v-model="formData.to_university_id"
                                                      :validate-error="errors[0]"
                                />
                            </ValidationProvider>
                        </b-form-group>
                        <b-form-group :label="$t('faculty')">
                            <ValidationProvider name="to_faculty" v-slot="{valid, errors}">
                                <b-form-input v-model="formData.to_faculty"
                                              :class="errors[0] ? 'is-invalid':''"
                                />
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                            </ValidationProvider>
                        </b-form-group>
                        <b-form-group :label="$t('program')">
                            <ValidationProvider name="to_program" v-slot="{valid, errors}">
                                <b-form-input v-model="formData.to_program"
                                              :class="errors[0] ? 'is-invalid':''"
                                />
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                            </ValidationProvider>
                        </b-form-group>
                    </b-card>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary" @click="updateForm" :disabled="formLoading">{{ $t('save') }}</b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    // Components
    import FacultySelectbox from '@/components/interactive-fields/FacultySelectbox';
    import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';
    import PeriodSelectbox from '@/components/interactive-fields/PeriodSelectbox';
    import ProgramSelectbox from '@/components/interactive-fields/ProgramSelectbox';
    import SchoolSelectbox from '@/components/interactive-fields/SchoolSelectbox';
    import SemestersSelectbox from '@/components/interactive-fields/SemestersSelectbox';
    import UniversitySelectbox from '@/components/interactive-fields/UniversitySelectbox';

    // Services
    import StudentProgramService from '@/services/StudentProgramService'

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'

    export default {
        components: {
            FacultySelectbox,
            ParameterSelectbox,
            PeriodSelectbox,
            ProgramSelectbox,
            SchoolSelectbox,
            SemestersSelectbox,
            UniversitySelectbox,

            ValidationProvider,
            ValidationObserver
        },
        props: {
            studentProgram: {
                type: Object,
                default: null
            }
        },
        created() {
            this.formId = this.studentProgram.id;
            this.formData = {
                from_university_id: this.studentProgram.from_university_id,
                from_faculty: this.studentProgram.from_faculty,
                from_program: this.studentProgram.from_program,
                from_number_of_semesters: this.studentProgram.from_number_of_semesters,
                from_cgpa: this.studentProgram.from_cgpa,

                to_university_id: this.studentProgram.to_university_id,
                to_faculty: this.studentProgram.to_faculty,
                to_program: this.studentProgram.to_program,
            }
        },
        data() {
            return {
                formId: null,
                formData: {},
                formLoading: false
            }
        },
        methods: {
            async updateForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid && this.formId) {
                    this.formLoading = true;
                    StudentProgramService.updateOtherUniversity(this.formId, this.formData)
                                         .then(response => {
                                             this.$emit('updateFormSuccess', true);
                                             this.$toast.success(this.$t('api.' + response.data.message));
                                         })
                                         .catch(e => {
                                             this.showErrors(e, this.$refs.formModalValidate)
                                         })
                                         .finally(() => {
                                             this.formLoading = false;
                                         })
                }
            }
        }
    }
</script>

<style scoped>
    .card-body{
        padding-bottom: 0px;
    }
</style>
