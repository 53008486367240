<template>
    <div>
        <h5 class="mt-1 mb-4">{{ $t('foreign_student_credentials') }}</h5>
        <b-row>
            <b-col cols="12" md="4">
                <b-card :header="$t('passport')" class="soft">
                    <div class="label-as-input-div">
                        <label>{{ $t('passport_country') }}</label>
                        <div>
                            {{ getObjectValue(studentProgram, 'passport_country_name') }}
                        </div>
                    </div>
                    <div class="label-as-input-div">
                        <label>{{ $t('passport_number') }}</label>
                        <div>
                            {{ getObjectValue(studentProgram, 'passport_number') }}
                        </div>
                    </div>
                </b-card>
            </b-col>
            <b-col cols="12" sm="6" md="4">
                <b-card :header="$t('residence_permit')" class="soft">
                    <div class="label-as-input-div">
                        <label>{{ $t('residence_permit_no') }}</label>
                        <div>
                            {{ getObjectValue(studentProgram, 'residence_permit_no') }}
                        </div>
                    </div>
                    <div class="label-as-input-div">
                        <label>{{ $t('residence_permit_validity_start_date') }}</label>
                        <div>
                            {{ studentProgram.residence_permit_start_date ? this.toLocaleDate(studentProgram.residence_permit_start_date) : '-' }}
                        </div>
                    </div>
                    <div class="label-as-input-div">
                        <label>{{ $t('residence_permit_validity_end_date') }}</label>
                        <div>
                            {{ studentProgram.residence_permit_end_date ? this.toLocaleDate(studentProgram.residence_permit_end_date) : '-' }}
                        </div>
                    </div>
                </b-card>
            </b-col>
            <b-col cols="12" sm="6" md="4">
                <b-card :header="$t('foreign_student')" class="soft">
                    <div class="label-as-input-div">
                        <label>{{ $t('document_type') }}</label>
                        <div>
                            {{ studentProgram.foreign_document_type_text }}
                        </div>
                    </div>
                    <div class="label-as-input-div">
                        <label>{{ $t('document_number') }}</label>
                        <div>
                            {{ getObjectValue(studentProgram, 'foreign_document_number') }}
                        </div>
                    </div>
                </b-card>
            </b-col>
        </b-row>

        <b-button variant="primary"
                  @click="updateFormShow"
                  v-if="checkPermission('studentprogram_updateforeign') && editMode">
            {{ changeText($t('edit')) }}
        </b-button>
        <CommonModal ref="formModal"
                     size="xl"
                     @bHideModalHeaderClose="clearFormData()"
                     :onHideOnlyX="true"
                     v-if="checkPermission('studentprogram_updateforeign') && editMode">
            >
            <template v-slot:CommonModalTitle>
                {{ changeText($t('foreign_student_credentials')) }}
            </template>
            <template v-slot:CommonModalContent>
                <update-form :studentProgram="studentProgram"
                             @updateFormSuccess="updateFormSuccess()"
                             v-if="formProcess=='update'"
                />
            </template>
        </CommonModal>
    </div>
</template>
<script>
    // Component
    import CommonModal from '@/components/elements/CommonModal';

    // Pages
    import UpdateForm from './UpdateForm';

    export default {
        components: {
            CommonModal,
            UpdateForm
        },
        props: {
            studentProgram: {
                type: Object,
                default: null
            },
            editMode: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                formProcess: null
            }
        },
        methods: {
            // Clear
            clearFormData() {
                this.formProcess = null
            },

            // Update
            updateFormShow() {
                this.formProcess = 'update'
                this.$refs.formModal.$refs.commonModal.show()
            },
            updateFormSuccess() {
                this.clearFormData();
                this.$refs.formModal.$refs.commonModal.hide()
                this.$emit('updatedData', true);
            }
        }
    }
</script>

