<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col md="4">
                    <ValidationProvider name="semester_id" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('semester')">
                            <semesters-selectbox v-model="formData.semester_id"
                                                 :validate-error="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="exam_type" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('exam_type')">
                            <b-form-input v-model="formData.exam_type"
                            />
<!--                            <parameter-selectbox v-model="formData.exam_type"-->
<!--                                                 code="registration_exam_types"-->
<!--                                                 :validate-error="errors[0]"-->
<!--                            />-->
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />

                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="placement_score_type" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('score_type')">
                            <b-form-input v-model="formData.placement_score_type"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />

                            <!--                            <parameter-selectbox v-model="formData.placement_score_type"-->
<!--                                                 code="placement_score_types"-->
<!--                                                 :is-filter-name-required="true"-->
<!--                                                 :filter-name="formData.exam_type"-->
<!--                                                 :filter-other-url="true"-->
<!--                                                 :validate-error="errors[0]"-->
<!--                            />-->
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="numerical_score" v-slot="{valid, errors}">
                        <b-form-group :label="$t('numerical_score')">
                            <b-form-input v-model="formData.numerical_score"
                                          :class="errors[0] ? 'is-invalid':''"
                                          type="number"
                                          min="0"
                                          max="999"
                                          step="0.00001"
                                          v-check-min-max-value

                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="verbal_score" v-slot="{valid, errors}">
                        <b-form-group :label="$t('verbal_score')">
                            <b-form-input v-model="formData.verbal_score"
                                          :class="errors[0] ? 'is-invalid':''"
                                          type="number"
                                          min="0"
                                          max="999"
                                          step="0.00001"
                                          v-check-min-max-value
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="equal_weight_score" v-slot="{valid, errors}">
                        <b-form-group :label="$t('equal_weight_score')">
                            <b-form-input v-model="formData.equal_weight_score"
                                          :class="errors[0] ? 'is-invalid':''"
                                          type="number"
                                          min="0"
                                          max="999"
                                          step="0.00001"
                                          v-check-min-max-value
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="placement_score" v-slot="{valid, errors}">
                        <b-form-group :label="$t('placement_score')">
                            <b-form-input v-model="formData.placement_score"
                                          :class="errors[0] ? 'is-invalid':''"
                                          type="number"
                                          min="0"
                                          max="999"
                                          step="0.00001"
                                          v-check-min-max-value
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="placement_success_order" v-slot="{valid, errors}">
                        <b-form-group :label="$t('placement_success_order')">
                            <b-form-input v-model="formData.placement_success_order"
                                          :class="errors[0] ? 'is-invalid':''"
                                          type="number"
                                          min="1"
                                          max="10000000"
                                          step="1"
                                          v-check-min-max-value
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="order_of_preference" v-slot="{valid, errors}">
                        <b-form-group :label="$t('order_of_preference')">
                            <b-form-input v-model="formData.order_of_preference"
                                          :class="errors[0] ? 'is-invalid':''"
                                          type="number"
                                          min="1"
                                          max="99"
                                          step="1"
                                          v-check-min-max-value
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary"
                          @click="createForm"
                          :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    // Components
    import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
    import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";

    // Services
    import PointService from "@/services/PointService";

    // Other
    import {ValidationProvider, ValidationObserver} from "vee-validate"

    export default {
        components: {
            SemestersSelectbox,
            ParameterSelectbox,

            ValidationProvider,
            ValidationObserver,
        },
        props: {
            studentProgram: {
                type: Object,
                default: null,
            }
        },
        data() {
            return {
                formData: {
                    semester_id: null,
                    exam_type: null,
                    placement_score_type: null,
                    numerical_score: null,
                    verbal_score: null,
                    equal_weight_score: null,
                    placement_score: null,
                    placement_success_order: null,
                    order_of_preference: null
                },
                formLoading: false,
            }
        },
        methods: {
            async createForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid && this.studentProgram.id) {
                    let formData = {
                        student_program_id: this.studentProgram.id,
                        ...this.formData
                    }
                    this.formLoading = true;
                    PointService.store(formData)
                                .then(response => {
                                    this.$toast.success(this.$t('api.' + response.data.message));
                                    this.$emit('createFormSuccess', true);
                                })
                                .catch(e => {
                                    this.showErrors(e, this.$refs.formModalValidate)
                                })
                                .finally(() => {
                                    this.formLoading = false
                                })
                }
            }
        }
    }
</script>

