<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row class="mb-3">
                <b-col cols="12" md="6">
                    <ValidationProvider name="military_status" rules="required" v-slot="{valid, errors}">
                        <b-form-group>
                            <label>{{ $t('document_type') }}</label>
                            <parameter-selectbox
                                v-model="documentTypeId"
                                code="missing_document_types"
                                :validate-error="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4">
                    <ValidationProvider name="document_due_date" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('document_due_date')">
                            <select-date v-model="documentDueDate"
                                         :validationError="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="2">
                    <fieldset class="form-group">
                        <legend class="bv-no-focus-ring col-form-label pt-0">&nbsp;</legend>
                        <b-button variant="primary"
                                  block
                                  @click="add">{{ $t('add') }}
                        </b-button>
                    </fieldset>
                </b-col>
            </b-row>
        </ValidationObserver>

        <b-table bordered
                 striped
                 small
                 show-empty
                 :fields="missingDocumentsFields"
                 :items="missingDocuments"
                 v-if="missingDocuments && missingDocuments.length"
        >
            <template #empty="scope">
                <div class="text-center py-2">{{ $t('no_result') }}</div>
            </template>
            <template #cell(document_type)="data">
                {{ typeof data.item.document_type_name != undefined ? data.item.document_type_name : '-' }}
            </template>
            <template #cell(document_due_date)="row">
                {{ row.item.document_due_date | dateFormat }}
            </template>
            <template #cell(delete)="data">
                <div class="d-flex">
                    <b-button variant="danger"
                              size="sm"
                              @click="remove(data.index)"
                    >
                        <i class="ri-close-line"></i>
                    </b-button>
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
// Components
import SelectDate from "@/components/interactive-fields/SelectDate";
import RegistrationDocumentTypeSelectbox from "@/components/interactive-fields/RegistrationDocumentTypeSelectbox";

// Services
import StudentProgramService from "@/services/StudentProgramService"

// Other
import {ValidationProvider, ValidationObserver} from "vee-validate"
import moment from "moment";

export default {
    components: {
        SelectDate,
        RegistrationDocumentTypeSelectbox,

        ValidationProvider,
        ValidationObserver,
    },
    props: {
        studentProgram: {
            type: Object,
            default: null,
        }
    },
    created() {
        this.formId = this.studentProgram.id;
        this.missingDocuments = this.studentProgram.missing_documents
    },
    watch: {
        studentProgram: {
            handler(value) {
                this.missingDocuments = value.missing_documents;
            },
            immediate: true,
        }
    },
    data() {
        return {
            formId: null,
            formData: [],

            missingDocuments: [],
            missingDocumentsFields: [
                {
                    key: 'document_type',
                    label: this.$t('document_type'),
                    thClass: 'text-center align-middle',
                    tdClass: 'text-center align-middle p-1',
                    sortable: true
                },
                {
                    key: 'document_due_date',
                    label: this.$t('document_due_date'),
                    thClass: 'text-center align-middle',
                    tdClass: 'text-center align-middle p-1',
                    sortable: true
                },
                {
                    key: 'delete',
                    label: '',
                    thClass: 'text-center',
                    tdClass: 'text-center width-30 p-1'
                }
            ],
            documentTypeId: null,
            documentDueDate: null,
        }
    },
    methods: {
        async add() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid && this.formId) {
                this.formData = [];
                if (this.missingDocuments && this.missingDocuments.length > 0) {
                    this.missingDocuments.forEach(item => {
                        this.formData.push({
                            'document_type_id': item.document_type_id,
                            'document_due_date': item.document_due_date,
                        });
                    });
                }

                this.formData.push({
                    'document_type_id': this.documentTypeId,
                    'document_due_date': this.documentDueDate ? moment(this.documentDueDate).format('YYYY-MM-DD') : null
                });

                this.update(this.formId, this.formData);
            }
        },
        update(formId, formData) {
            StudentProgramService.updateMissingDocument(this.formId, this.formData)
                .then(response => {
                    this.$emit('updateFormSuccess', true);
                    this.$toast.success(this.$t('api.' + response.data.message));
                })
                .then(() => {
                    this.documentTypeId = null
                    this.documentDueDate = null
                    this.$refs.formModalValidate.reset();
                })
                .catch(e => {
                    this.showErrors(e);
                })
        },
        remove(index) {
            this.$swal.fire({
                text: this.$t('are_you_sure_to_delete'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no'),
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.missingDocuments.splice(index, 1);

                        this.formData = [];
                        if (this.missingDocuments && this.missingDocuments.length > 0) {
                            this.missingDocuments.forEach(item => {
                                this.formData.push({
                                    'document_type_id': item.document_type_id,
                                    'document_due_date': item.document_due_date,
                                });
                            });
                        }

                        this.update(this.formId, this.formData);
                    }
                });
        }
    }
}
</script>

