<template>
    <div>
        <h5 class="mt-1 mb-4">{{ $t('contact_information') }}</h5>
        <b-row class="mb-4">
            <b-col cols="12" md="4">
                <b-card :header="'BAU '+$t('email')" class="soft h-100">
                    <div class="label-as-input-div">
                        <label>{{ 'BAU ' + $t('email') }}</label>
                        <div>{{ this.getObjectValue(studentProgram, 'email') }}</div>
                    </div>
                    <div class="label-as-input-div">
                        <label>{{ $t('personal_email') }}</label>
                        <div>{{ this.getObjectValue(studentProgram, 'personal_email') }}</div>
                    </div>
                </b-card>
            </b-col>
            <b-col cols="12" md="4">
                <b-card :header="$t('telephone')" class="soft h-100">
                    <div class="label-as-input-div">
                        <label>{{ $t('mobile_number') }}</label>
                        <div>{{ this.getObjectValue(studentProgram, 'mobile_tel') }}</div>
                    </div>
                    <div class="label-as-input-div">
                        <label>{{ $t('home_number') }}</label>
                        <div>{{ this.getObjectValue(studentProgram, 'home_tel') }}</div>
                    </div>
                </b-card>
            </b-col>
            <b-col cols="12" md="4">
                <b-card :header="$t('emergency_person')" class="soft h-100">
                    <div class="label-as-input-div">
                        <label>{{ $t('degree_of_proximity') }}</label>
                        <div>{{ this.getObjectValue(studentProgram, 'emergency_person_relation') }}</div>
                    </div>
                    <div class="label-as-input-div">
                        <label>{{ $t('name') + ' ' + $t('surname') }}</label>
                        <div>{{ this.getObjectValue(studentProgram, 'emergency_person_name') }}</div>
                    </div>
                    <div class="label-as-input-div">
                        <label>{{ $t('telephone') }}</label>
                        <div>{{ this.getObjectValue(studentProgram, 'emergency_person_phone') }}</div>
                    </div>
                </b-card>
            </b-col>
        </b-row>
        <b-button variant="primary"
                  @click="updateFormShow"
                  v-if="checkPermission('studentprogram_updatecontact') && editMode">
            {{ $t('edit') }}
        </b-button>
        <CommonModal ref="formModal" size="xl"
                     :onHideOnlyX="true"
                     v-if="checkPermission('studentprogram_updatecontact') && editMode">
            <template v-slot:CommonModalTitle>
                {{ changeText($t('edit')) }}
            </template>
            <template v-slot:CommonModalContent>
                <update-form :studentProgram="studentProgram"
                             @updateFormSuccess="updateFormSuccess()"
                             v-if="formProcess=='update'"
                />
            </template>
        </CommonModal>
    </div>
</template>
<script>
    // Component
    import CommonModal from '@/components/elements/CommonModal';

    // Pages
    import UpdateForm from './UpdateForm';

    export default {
        components: {
            CommonModal,
            UpdateForm
        },
        props: {
            studentProgram: {
                type: Object,
                default: null
            },
            editMode: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                formProcess: null
            }
        },
        methods: {
            // Clear
            clearFormData() {
                this.formProcess = null
            },

            // Update
            updateFormShow() {
                this.formProcess = 'update'
                this.$refs.formModal.$refs.commonModal.show()
            },
            updateFormSuccess() {
                this.clearFormData();
                this.$refs.formModal.$refs.commonModal.hide()
                this.$emit('updatedData', true);
            }
        }
    }
</script>

