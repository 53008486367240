<template>
	<div>
		<multiselect
			:placeholder="$t('select')"
			v-model="selected"
			:options="options"
			:class="validateError != '' ? 'box-border-color':''"
			@input="handleInput"
			label="text"
			track-by="value"
			:multiple="false"
			:select-label="''"
			:selected-label="''"
			:deselect-label="''"
			:searchable="true">
            <span slot="noOptions">
                {{ $t('no_options') }}
            </span>
			<span slot="noResult">
                {{ $t('no_result') }}
            </span>
		</multiselect>
		<span class="invalid-feedback-custom" v-show="validateError" v-html="validateError"/>
	</div>
</template>

<script>
	import SchoolService from "@/services/SchoolService";
    import setSelected from "@/helpers/setSelected";
	import qs from "qs";
	
	export default {
		props: {
			countryId: {
				type: Number,
				default: null
			},
			isCountryRequired: {
				type: Boolean,
				default: false
			},
			value: {
				default: null
			},
			validateError: {
				type: String,
				default: ''
			},
            valueType: {
                type: String,
                default: 'id'
            }
		},
		data() {
			return {
				selected: null,
				options: [],
			}
		},
		watch: {
			countryId: function (newValue, oldValue) {
				if (newValue && newValue != oldValue) {
					this.get();
				}
			},
			value: function (newValue) {
				if (this.options.length > 0) {
					this.selected = this.options.filter(c => c.value == newValue);
				}
			},
		},
		methods: {
            setSelected,
			get() {
				if (this.isCountryRequired && !this.countryId) {
					return;
				}
				this.clearOptions()
				
				let filter = {};
				if (this.countryId) {
					filter.country_id = this.countryId;
				}
				
				const config = {
					params: {
						filter: filter,
						sort: ('name'),
						limit: -1
					},
					paramsSerializer: (params) => qs.stringify(params, {encode: false})
				};
				
				SchoolService.getAll(config)
				             .then(response => {
					             const data = response.data.data;
					             data.map(item => {
						             this.options.push({
							             value: item[this.valueType],
							             text: item.name,
						             });
					             });

				             }).then(() => {
                                    this.selected = this.setSelected(this.value, this.options)
                                });
			},
			clearOptions() {
				this.options = [];
				this.selected = null;
			},
			handleInput(event) {
				if (event && event.value) {
					this.$emit('input', event.value);
				}
				else {
					this.$emit('input', null);
				}
			}
		},
		created() {
			this.get();
		},
	};
</script>
