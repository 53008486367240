<template>
    <div>
        <h5 class="mt-1 mb-4">{{ $t('documents') }}</h5>
        <hr v-show="loading"/>
        <div class="position-relative">
            <loading v-show="loading"
                     :description-show="true"/>
            <b-table bordered
                     striped
                     responsive
                     :items="documents"
                     :fields="documentFields"
                     :empty-text="$t('there_are_no_records')"
                     show-empty
                     class="mb-3 table-dropdown no-scrollbar border rounded"
                     v-show="!loading"
            >
                <template #cell(date)="data">
                    {{ data.value }}
                </template>
                <template #cell(type)="data">
                    {{ data.value }}
                </template>
                <template #cell(download)="data">
                    <b-button :long-request="true" variant="primary" size="sm" block @click="getDocument(data.item.id)">
                        {{ $t('download') }}
                    </b-button>
                </template>
            </b-table>
        </div>
    </div>
</template>
<script>
    // Component
    import Loading from '@/components/elements/Loading';

    // Service
    import StudentProgramService from '@/services/StudentProgramService';

    export default {
        components: {
            Loading
        },
        props: {
            studentProgram: {
                type: Object,
                default: null
            },
            editMode: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                documents: [],
                documentFields: [
                    {
                        key: 'date',
                        label: this.toUpperCase('date'),
                        sortable: true,
                        thClass: 'text-center',
                        tdClass: 'align-middle pd-1 width-110 text-center'
                    },
                    {
                        key: 'type',
                        label: this.toUpperCase('type'),
                        sortable: true,
                        tdClass: 'align-middle pd-1 '
                    },
                    {
                        key: 'download',
                        label: '',
                        sortable: false,
                        tdClass: 'align-middle pd-1 width-100'
                    }
                ],
                loading: false
            }
        },
        mounted() {
            this.getDocuments()
        },
        methods: {
            getDocuments() {
                this.loading = true;
                StudentProgramService.documents(this.studentProgram.id)
                                     .then(response => {
                                         this.documents = response.data.data;
                                     })
                                     .catch((e) => {
                                         this.showErrors(e)
                                     })
                                     .finally(() => {
                                         this.loading = false;
                                     })
            },
            getDocument(id) {
                StudentProgramService.document(id)
                                     .then(response => {
                                         let url = response.data
                                         this.downloadItem(url, 'asd');
                                     })
                                     .catch((e) => {
                                         this.showErrors(e)
                                     })
            },

            async downloadItem(url) {
                var link = document.createElement('a');
                link.href = url;
                link.target="_blank";
                link.download = 'file.pdf';
                link.dispatchEvent(new MouseEvent('click'));
            }
        }
    }
</script>

