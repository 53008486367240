<template>
    <div>
        <b-row>
            <b-col cols="12" md="4">
                <b-form-group :label="$t('education_status')">
                    <div class="label-as-input">
                        {{ formData.type_name }}
                    </div>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="8">
                <div v-if="formData.type=='high_school'">
                    <b-form-group :label="$t('school')" >
                        <div class="label-as-input">
                            {{ formData.school ? formData.school.name : '-' }}
                        </div>
                    </b-form-group>
                </div>
                <div v-else>
                    <b-row>
                        <b-col cols="12" md="6">
                            <b-form-group :label="$t('university_type')" >
                                <div class="label-as-input">
                                    {{ formData.school ? formData.school.type_name:'-' }}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6">
                            <b-form-group :label="$t('country')" >
                                <div class="label-as-input">
                                    {{ formData.school ? formData.school.country_name:'-' }}
                                </div>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-form-group :label="$t('university')" >
                        <div class="label-as-input">
                            {{ formData.school ? formData.school.name : '-' }}
                        </div>
                    </b-form-group>
                    <b-form-group :label="$t('program')" >
                        <div class="label-as-input">
                            {{ formData.program }}
                        </div>
                    </b-form-group>
                </div>
                <b-row>
                    <b-col cols="12" md="4">
                        <b-form-group :label="$t('graduate_date')">
                            <div class="label-as-input">
                                {{ formData.graduate_date ? toLocaleDate(formData.graduate_date) : '-' }}
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="4">
                        <b-form-group :label="$t('diploma_number')">
                            <div class="label-as-input">
                                {{ getObjectValue(formData, 'diploma_number') }}
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="4">
                        <b-form-group :label="$t('diploma_point')">
                            <div class="label-as-input">
                                {{ getObjectValue(formData, 'diploma_point') }}
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import StudentEducationService from '@/services/StudentEducationService';

    export default {
        props: {
            studentProgram: {
                type: Object,
                default: null
            },
            formId: {
                type: Number
            }
        },
        data() {
            return {
                formLoading: false,
                formData: {}
            }
        },
        created() {
            this.get(this.formId)
        },
        methods: {
            async get(id) {
                this.formLoading = true
                StudentEducationService.get(id)
                                       .then(response => {
                                           this.formData = response.data.data;
                                       })
                                       .catch((e) => {
                                           this.showErrors(e)
                                       })
                                       .finally(() => {
                                           this.formLoading = false;
                                       })
            }
        }
    }
</script>
