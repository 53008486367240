export default[
    {
        "text": "select",
        "value":"null"
    },
    {
        "text": "Afghanistan",
        "value": "+93",
        "maxlength": 9
    },
    {
        "text": "Albania",
        "value": "+355",
        "maxlength": 8
    },
    {
        "text": "Algeria",
        "value": "+213",
        "maxlength": 9
    },
    {
        "text": "Andorra",
        "value": "+376",
        "maxlength": 6
    },
    {
        "text": "Angola",
        "value": "+244",
        "maxlength": 9
    },
    {
        "text": "Antigua and Barbuda",
        "value": "+1-268",
        "maxlength": 7
    },
    {
        "text": "Argentina",
        "value": "+54",
        "maxlength": 10
    },
    {
        "text": "Armenia",
        "value": "+374",
        "maxlength": 8
    },
    {
        "text": "Australia",
        "value": "+61",
        "maxlength": 9
    },
    {
        "text": "Austria",
        "value": "+43",
        "maxlength": 13
    },
    {
        "text": "Azerbaijan",
        "value": "+994",
        "maxlength": 9
    },
    {
        "text": "Bahamas",
        "value": "+1-242",
        "maxlength": 7
    },
    {
        "text": "Bahrain",
        "value": "+973",
        "maxlength": 8
    },
    {
        "text": "Bangladesh",
        "value": "+880",
        "maxlength": 10
    },
    {
        "text": "Barbados",
        "value": "+1-246",
        "maxlength": 7
    },
    {
        "text": "Belarus",
        "value": "+375",
        "maxlength": 9
    },
    {
        "text": "Belgium",
        "value": "+32",
        "maxlength": 9
    },
    {
        "text": "Belize",
        "value": "+501",
        "maxlength": 7
    },
    {
        "text": "Benin",
        "value": "+229",
        "maxlength": 8
    },
    {
        "text": "Bhutan",
        "value": "+975",
        "maxlength": 8
    },
    {
        "text": "Bolivia",
        "value": "+591",
        "maxlength": 8
    },
    {
        "text": "Bosnia and Herzegovina",
        "value": "+387",
        "maxlength": 8
    },
    {
        "text": "Botswana",
        "value": "+267",
        "maxlength": 7
    },
    {
        "text": "Brazil",
        "value": "+55",
        "maxlength": 11
    },
    {
        "text": "Brunei",
        "value": "+673",
        "maxlength": 7
    },
    {
        "text": "Bulgaria",
        "value": "+359",
        "maxlength": 9
    },
    {
        "text": "Burkina Faso",
        "value": "+226",
        "maxlength": 8
    },
    {
        "text": "Burundi",
        "value": "+257",
        "maxlength": 8
    },
    {
        "text": "Cabo Verde",
        "value": "+238",
        "maxlength": 7
    },
    {
        "text": "Cambodia",
        "value": "+855",
        "maxlength": 8
    },
    {
        "text": "Cameroon",
        "value": "+237",
        "maxlength": 8
    },
    {
        "text": "Central African Republic",
        "value": "+236",
        "maxlength": 8
    },
    {
        "text": "Chad",
        "value": "+235",
        "maxlength": 8
    },
    {
        "text": "Chile",
        "value": "+56",
        "maxlength": 9
    },
    {
        "text": "China",
        "value": "+86",
        "maxlength": 11
    },
    {
        "text": "Colombia",
        "value": "+57",
        "maxlength": 10
    },
    {
        "text": "Comoros",
        "value": "+269",
        "maxlength": 7
    },
    {
        "text": "Congo",
        "value": "+242",
        "maxlength": 9
    },
    {
        "text": "Congo, Democratic Republic of the",
        "value": "+243",
        "maxlength": 9
    },
    {
        "text": "Costa Rica",
        "value": "+506",
        "maxlength": 8
    },
    {
        "text": "Croatia",
        "value": "+385",
        "maxlength": 10
    },
    {
        "text": "Cuba",
        "value": "+53",
        "maxlength": 8
    },
    {
        "text": "Cyprus",
        "value": "+357",
        "maxlength": 8
    },
    {
        "text": "Czech Republic",
        "value": "+420",
        "maxlength": 9
    },
    {
        "text": "Denmark",
        "value": "+45",
        "maxlength": 8
    },
    {
        "text": "Djibouti",
        "value": "+253",
        "maxlength": 7
    },
    {
        "text": "Dominica",
        "value": "+1-767",
        "maxlength": 7
    },
    {
        "text": "Dominican Republic",
        "value": "+1-809, 1-829, 1-849",
        "maxlength": 10
    },
    {
        "text": "Ecuador",
        "value": "+593",
        "maxlength": 9
    },
    {
        "text": "Egypt",
        "value": "+20",
        "maxlength": 10
    },
    {
        "text": "El Salvador",
        "value": "+503",
        "maxlength": 8
    },
    {
        "text": "Equatorial Guinea",
        "value": "+240",
        "maxlength": 9
    },
    {
        "text": "Eritrea",
        "value": "+291",
        "maxlength": 7
    },
    {
        "text": "Estonia",
        "value": "+372",
        "maxlength": 8
    },
    {
        "text": "Eswatini",
        "value": "+268",
        "maxlength": 8
    },
    {
        "text": "Ethiopia",
        "value": "+251",
        "maxlength": 10
    },
    {
        "text": "Fiji",
        "value": "+679",
        "maxlength": 7
    },
    {
        "text": "Finland",
        "value": "+358",
        "maxlength": 12
    },
    {
        "text": "France",
        "value": "+33",
        "maxlength": 10
    },
    {
        "text": "Gabon",
        "value": "+241",
        "maxlength": 8
    },
    {
        "text": "Gambia",
        "value": "+220",
        "maxlength": 7
    },
    {
        "text": "Georgia",
        "value": "+995",
        "maxlength": 9
    },
    {
        "text": "Germany",
        "value": "+49",
        "maxlength": 11
    },
    {
        "text": "Ghana",
        "value": "+233",
        "maxlength": 9
    },
    {
        "text": "Greece",
        "value": "+30",
        "maxlength": 10
    },
    {
        "text": "Greenland",
        "value": "+299",
        "maxlength": 6
    },
    {
        "text": "Grenada",
        "value": "+1-473",
        "maxlength": 7
    },
    {
        "text": "Guadeloupe",
        "value": "+590",
        "maxlength": 9
    },
    {
        "text": "Guam",
        "value": "+1-671",
        "maxlength": 7
    },
    {
        "text": "Guatemala",
        "value": "+502",
        "maxlength": 8
    },
    {
        "text": "Guinea",
        "value": "+224",
        "maxlength": 8
    },
    {
        "text": "Guinea-Bissau",
        "value": "+245",
        "maxlength": 8
    },
    {
        "text": "Guyana",
        "value": "+592",
        "maxlength": 8
    },
    {
        "text": "Haiti",
        "value": "+509",
        "maxlength": 8
    },
    {
        "text": "Honduras",
        "value": "+504",
        "maxlength": 8
    },
    {
        "text": "Hong Kong",
        "value": "+852",
        "maxlength": 8
    },
    {
        "text": "Hungary",
        "value": "+36",
        "maxlength": 11
    },
    {
        "text": "Iceland",
        "value": "+354",
        "maxlength": 7
    },
    {
        "text": "India",
        "value": "+91",
        "maxlength": 10
    },
    {
        "text": "Indonesia",
        "value": "+62",
        "maxlength": 12
    },
    {
        "text": "Iran",
        "value": "+98",
        "maxlength": 11
    },
    {
        "text": "Iraq",
        "value": "+964",
        "maxlength": 10
    },
    {
        "text": "Ireland",
        "value": "+353",
        "maxlength": 9
    },
    {
        "text": "Israel",
        "value": "+972",
        "maxlength": 9
    },
    {
        "text": "Italy",
        "value": "+39",
        "maxlength": 10
    },
    {
        "text": "Jamaica",
        "value": "+1-876",
        "maxlength": 7
    },
    {
        "text": "Japan",
        "value": "+81",
        "maxlength": 11
    },
    {
        "text": "Jordan",
        "value": "+962",
        "maxlength": 10
    },
    {
        "text": "Kazakhstan",
        "value": "+7",
        "maxlength": 11
    },
    {
        "text": "Kenya",
        "value": "+254",
        "maxlength": 10
    },
    {
        "text": "Kiribati",
        "value": "+686",
        "maxlength": 7
    },
    {
        "text": "Korea, North",
        "value": "+850",
        "maxlength": 8
    },
    {
        "text": "Korea, South",
        "value": "+82",
        "maxlength": 11
    },
    {
        "text": "Kuwait",
        "value": "+965",
        "maxlength": 8
    },
    {
        "text": "Kyrgyzstan",
        "value": "+996",
        "maxlength": 9
    },
    {
        "text": "Laos",
        "value": "+856",
        "maxlength": 8
    },
    {
        "text": "Latvia",
        "value": "+371",
        "maxlength": 8
    },
    {
        "text": "Lebanon",
        "value": "+961",
        "maxlength": 9
    },
    {
        "text": "Lesotho",
        "value": "+266",
        "maxlength": 8
    },
    {
        "text": "Liberia",
        "value": "+231",
        "maxlength": 7
    },
    {
        "text": "Libya",
        "value": "+218",
        "maxlength": 9
    },
    {
        "text": "Liechtenstein",
        "value": "+423",
        "maxlength": 9
    },
    {
        "text": "Lithuania",
        "value": "+370",
        "maxlength": 8
    },
    {
        "text": "Luxembourg",
        "value": "+352",
        "maxlength": 8
    },
    {
        "text": "Madagascar",
        "value": "+261",
        "maxlength": 9
    },
    {
        "text": "Malawi",
        "value": "+265",
        "maxlength": 8
    },
    {
        "text": "Malaysia",
        "value": "+60",
        "maxlength": 10
    },
    {
        "text": "Maldives",
        "value": "+960",
        "maxlength": 7
    },
    {
        "text": "Mali",
        "value": "+223",
        "maxlength": 8
    },
    {
        "text": "Malta",
        "value": "+356",
        "maxlength": 8
    },
    {
        "text": "Marshall Islands",
        "value": "+692",
        "maxlength": 7
    },
    {
        "text": "Mauritania",
        "value": "+222",
        "maxlength": 8
    },
    {
        "text": "Mauritius",
        "value": "+230",
        "maxlength": 8
    },
    {
        "text": "Mexico",
        "value": "+52",
        "maxlength": 10
    },
    {
        "text": "Micronesia",
        "value": "+691",
        "maxlength": 7
    },
    {
        "text": "Moldova",
        "value": "+373",
        "maxlength": 8
    },
    {
        "text": "Monaco",
        "value": "+377",
        "maxlength": 8
    },
    {
        "text": "Mongolia",
        "value": "+976",
        "maxlength": 8
    },
    {
        "text": "Montenegro",
        "value": "+382",
        "maxlength": 8
    },
    {
        "text": "Morocco",
        "value": "+212",
        "maxlength": 9
    },
    {
        "text": "Mozambique",
        "value": "+258",
        "maxlength": 9
    },
    {
        "text": "Myanmar",
        "value": "+95",
        "maxlength": 9
    },
    {
        "text": "Namibia",
        "value": "+264",
        "maxlength": 9
    },
    {
        "text": "Nauru",
        "value": "+674",
        "maxlength": 7
    },
    {
        "text": "Nepal",
        "value": "+977",
        "maxlength": 10
    },
    {
        "text": "Netherlands",
        "value": "+31",
        "maxlength": 9
    },
    {
        "text": "New Caledonia",
        "value": "+687",
        "maxlength": 7
    },
    {
        "text": "New Zealand",
        "value": "+64",
        "maxlength": 9
    },
    {
        "text": "Nicaragua",
        "value": "+505",
        "maxlength": 8
    },
    {
        "text": "Niger",
        "value": "+227",
        "maxlength": 8
    },
    {
        "text": "Nigeria",
        "value": "+234",
        "maxlength": 10
    },
    {
        "text": "Niue",
        "value": "+683",
        "maxlength": 5
    },
    {
        "text": "Norfolk Island",
        "value": "+672",
        "maxlength": 7
    },
    {
        "text": "North Macedonia",
        "value": "+389",
        "maxlength": 8
    },
    {
        "text": "Northern Mariana Islands",
        "value": "+1-670",
        "maxlength": 7
    },
    {
        "text": "Norway",
        "value": "+47",
        "maxlength": 8
    },
    {
        "text": "Oman",
        "value": "+968",
        "maxlength": 8
    },
    {
        "text": "Pakistan",
        "value": "+92",
        "maxlength": 10
    },
    {
        "text": "Palau",
        "value": "+680",
        "maxlength": 7
    },
    {
        "text": "Palestine",
        "value": "+970",
        "maxlength": 9
    },
    {
        "text": "Panama",
        "value": "+507",
        "maxlength": 8
    },
    {
        "text": "Papua New Guinea",
        "value": "+675",
        "maxlength": 9
    },
    {
        "text": "Paraguay",
        "value": "+595",
        "maxlength": 10
    },
    {
        "text": "Peru",
        "value": "+51",
        "maxlength": 9
    },
    {
        "text": "Philippines",
        "value": "+63",
        "maxlength": 11
    },
    {
        "text": "Pitcairn Islands",
        "value": "+64",
        "maxlength": 4
    },
    {
        "text": "Poland",
        "value": "+48",
        "maxlength": 9
    },
    {
        "text": "Portugal",
        "value": "+351",
        "maxlength": 9
    },
    {
        "text": "Puerto Rico",
        "value": "+1-787, 1-939",
        "maxlength": 7
    },
    {
        "text": "Qatar",
        "value": "+974",
        "maxlength": 8
    },
    {
        "text": "Romania",
        "value": "+40",
        "maxlength": 10
    },
    {
        "text": "Russia",
        "value": "+7",
        "maxlength": 11
    },
    {
        "text": "Rwanda",
        "value": "+250",
        "maxlength": 9
    },
    {
        "text": "Saint Kitts and Nevis",
        "value": "+1-869",
        "maxlength": 7
    },
    {
        "text": "Saint Lucia",
        "value": "+1-758",
        "maxlength": 7
    },
    {
        "text": "Saint Vincent and the Grenadines",
        "value": "+1-784",
        "maxlength": 7
    },
    {
        "text": "Samoa",
        "value": "+685",
        "maxlength": 7
    },
    {
        "text": "San Marino",
        "value": "+378",
        "maxlength": 10
    },
    {
        "text": "Sao Tome and Principe",
        "value": "+239",
        "maxlength": 7
    },
    {
        "text": "Saudi Arabia",
        "value": "+966",
        "maxlength": 9
    },
    {
        "text": "Senegal",
        "value": "+221",
        "maxlength": 8
    },
    {
        "text": "Serbia",
        "value": "+381",
        "maxlength": 9
    },
    {
        "text": "Seychelles",
        "value": "+248",
        "maxlength": 7
    },
    {
        "text": "Sierra Leone",
        "value": "+232",
        "maxlength": 8
    },
    {
        "text": "Singapore",
        "value": "+65",
        "maxlength": 8
    },
    {
        "text": "Sint Maarten",
        "value": "+1-721",
        "maxlength": 7
    },
    {
        "text": "Slovakia",
        "value": "+421",
        "maxlength": 9
    },
    {
        "text": "Slovenia",
        "value": "+386",
        "maxlength": 8
    },
    {
        "text": "Solomon Islands",
        "value": "+677",
        "maxlength": 7
    },
    {
        "text": "Somalia",
        "value": "+252",
        "maxlength": 8
    },
    {
        "text": "South Africa",
        "value": "+27",
        "maxlength": 10
    },
    {
        "text": "South Sudan",
        "value": "+211",
        "maxlength": 9
    },
    {
        "text": "Spain",
        "value": "+34",
        "maxlength": 9
    },
    {
        "text": "Sri Lanka",
        "value": "+94",
        "maxlength": 10
    },
    {
        "text": "Sudan",
        "value": "+249",
        "maxlength": 9
    },
    {
        "text": "Suriname",
        "value": "+597",
        "maxlength": 7
    },
    {
        "text": "Sweden",
        "value": "+46",
        "maxlength": 10
    },
    {
        "text": "Switzerland",
        "value": "+41",
        "maxlength": 10
    },
    {
        "text": "Syria",
        "value": "+963",
        "maxlength": 9
    },
    {
        "text": "Taiwan",
        "value": "+886",
        "maxlength": 10
    },
    {
        "text": "Tajikistan",
        "value": "+992",
        "maxlength": 9
    },
    {
        "text": "Tanzania",
        "value": "+255",
        "maxlength": 10
    },
    {
        "text": "Thailand",
        "value": "+66",
        "maxlength": 10
    },
    {
        "text": "Timor-Leste",
        "value": "+670",
        "maxlength": 9
    },
    {
        "text": "Togo",
        "value": "+228",
        "maxlength": 8
    },
    {
        "text": "Tokelau",
        "value": "+690",
        "maxlength": 6
    },
    {
        "text": "Tonga",
        "value": "+676",
        "maxlength": 6
    },
    {
        "text": "Trinidad and Tobago",
        "value": "+1-868",
        "maxlength": 7
    },
    {
        "text": "Tunisia",
        "value": "+216",
        "maxlength": 8
    },
    {
        "text": "Turkey",
        "value": "+90",
        "maxlength": 10
    },
    {
        "text": "Turkmenistan",
        "value": "+993",
        "maxlength": 8
    },
    {
        "text": "Tuvalu",
        "value": "+688",
        "maxlength": 5
    },
    {
        "text": "Uganda",
        "value": "+256",
        "maxlength": 10
    },
    {
        "text": "Ukraine",
        "value": "+380",
        "maxlength": 9
    },
    {
        "text": "United Arab Emirates",
        "value": "+971",
        "maxlength": 9
    },
    {
        "text": "United Kingdom",
        "value": "+44",
        "maxlength": 10
    },
    {
        "text": "United States",
        "value": "+1",
        "maxlength": 10
    },
    {
        "text": "Uruguay",
        "value": "+598",
        "maxlength": 8
    },
    {
        "text": "Uzbekistan",
        "value": "+998",
        "maxlength": 9
    },
    {
        "text": "Vanuatu",
        "value": "+678",
        "maxlength": 7
    },
    {
        "text": "Vatican City",
        "value": "+379",
        "maxlength": 9
    },
    {
        "text": "Venezuela",
        "value": "+58",
        "maxlength": 10
    },
    {
        "text": "Vietnam",
        "value": "+84",
        "maxlength": 11
    },
    {
        "text": "Western Sahara",
        "value": "+212",
        "maxlength": 9
    },
    {
        "text": "Yemen",
        "value": "+967",
        "maxlength": 9
    },
    {
        "text": "Zambia",
        "value": "+260",
        "maxlength": 10
    },
    {
        "text": "Zimbabwe",
        "value": "+263",
        "maxlength": 9
    }
]
