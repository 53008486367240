<template>
    <div>
        <loading v-show="formLoading"></loading>
        <div>
            <b-row>
                <b-col md="4">
                    <div class="label-as-input-div">
                        <label>{{ $t('semester') }}</label>
                        <div>
                            {{ formData.semester ? formData.semester.academic_year + ' ' + formData.semester.semester_text : '-' }}
                        </div>
                    </div>
                </b-col>
                <b-col md="4">
                    <div class="label-as-input-div">
                        <label>{{ $t('exam_type') }}</label>
                        <div>
                            {{ this.getObjectValue(formData, 'exam_type') }}
                        </div>
                    </div>
                </b-col>
                <b-col md="4">
                    <div class="label-as-input-div">
                        <label>{{ $t('score_type') }}</label>
                        <div>
                            {{ this.getObjectValue(formData, 'placement_score_type') }}
                        </div>
                    </div>
                </b-col>
                <b-col md="4">
                    <div class="label-as-input-div">
                        <label>{{ $t('numerical_score') }}</label>
                        <div>
                            {{ this.getObjectValue(formData, 'numerical_score') }}
                        </div>
                    </div>
                </b-col>
                <b-col md="4">
                    <div class="label-as-input-div">
                        <label>{{ $t('verbal_score') }}</label>
                        <div>
                            {{ this.getObjectValue(formData, 'verbal_score') }}
                        </div>
                    </div>
                </b-col>
                <b-col md="4">
                    <div class="label-as-input-div">
                        <label>{{ $t('equal_weight_score') }}</label>
                        <div>
                            {{ this.getObjectValue(formData, 'equal_weight_score') }}
                        </div>
                    </div>
                </b-col>
                <b-col md="4">
                    <div class="label-as-input-div">
                        <label>{{ $t('placement_score') }}</label>
                        <div>
                            {{ this.getObjectValue(formData, 'placement_score') }}
                        </div>
                    </div>
                </b-col>
                <b-col md="4">
                    <div class="label-as-input-div">
                        <label>{{ $t('placement_success_order') }}</label>
                        <div>
                            {{ this.getObjectValue(formData, 'placement_success_order') }}
                        </div>
                    </div>
                </b-col>
                <b-col md="4">
                    <div class="label-as-input-div">
                        <label>{{ $t('order_of_preference') }}</label>
                        <div>
                            {{ this.getObjectValue(formData, 'order_of_preference') }}
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
    import PointService from '@/services/PointService';
    import Loading from '@/components/elements/Loading'

    export default {
        components: {
            Loading
        },
        props: {
            studentProgram: {
                type: Object,
                default: null
            },
            formId: {
                type: Number
            }
        },
        data() {
            return {
                formLoading: false,
                formData: {}
            }
        },
        created() {
            this.get(this.formId)
        },
        methods: {
            async get(id) {
                this.formLoading = true
                PointService.get(id)
                            .then(response => {
                                this.formData = response.data.data;
                            })
                            .catch(() => {
                                this.showErrors(e)
                            })
                            .finally(() => {
                                this.formLoading = false
                            })
            }
        }
    }
</script>

